<template>
  <div id="games">
    <h1 v-if="onlyFirst == false">Our Games</h1>
    <div class="game">
      <div class="game-info game-left">
        <h2>Super Marxist Twins</h2>
        <p class="links">
          <a href="https://store.steampowered.com/app/1446370/Super_Marxist_Twins/" target="_blank">Now Available on <img src="../assets/steam36x36.png" alt="Steam Early Access"></a>
          <a href="https://discord.gg/BkY3ZGT" target="_blank"><img src="../assets/discord44x34.png" alt="Discord"></a>
          <a href="https://www.igdb.com/games/super-marxist-twins/presskit" target="_blank">Press Kit</a>
        </p>
        <p class="meta-info">
          Genre: Platformer<br />
          Release: 10/25/2024<br />
          Platform: PC
        </p>
        <p>Free Demo Available on Steam!<br />
        Join our mailing list for a free digital art book! 
        <a class="ml-onclick-form" href="javascript:void(0)" onclick="ml('show', 'Ux67wH', true)"><img src="../assets/mail36x36.png" alt="Open Mailing List Form"></a></p>
        
        <p>Ever wonder what it would look like if a Soviet state sponsored game developer designed a game to compete with classic side-scrollers while subverting western youth? No? Well we did.</p>
        <p>The evil Tsar Boarov, and his army of capitalist pigs, have stolen The Nation's resources and left The People with nothing. Now it's up to brothers Mikhail and Ludmil to collect the stolen resources and defeat Tsar Boarov once and for all.</p>
        <p>In communist paradise, money does not exist, so surrender your funds and buy Super Marxist Twins! Purchase the game for yourself! Purchase the game for your friends! Purchase the game for The Greater Good.</p>
      </div>
      <div class="game-image game-right">
        <h2></h2>
        <img src="../assets/verticalgamecover6x9_template.jpg" alt="Super Marxist Twins Image"/>
      </div>
    </div>
    <div v-if="onlyFirst == false" class="game">
      <div class="game-info game-right">
        <h2>Lost or Found</h2>
        <p class="links">
          <a href="https://vecima.itch.io/lost-or-found-word-jam" target="_blank"><img src="../assets/itch_logo_41x35.png" alt="Itch.io logo"></a>
        </p>
        <p class="meta-info">
          Genre: Text Adventure<br />
          Release: 2017<br />
          Platform: PC
        </p>
        <p>Try it out, it's free!</p>
        <p>Help your quiet neighbor, Rat, through a dangerous ordeal by giving him directions and translating his descriptions, as he tells you what he sees in partial English.</p>
        <p>The Philly Dev Night group voted "Lost or Found" as their favorite entry in the "Words Jam" at the end of the event (06/15/17). Thanks to everyone who came out and played! :)</p>
      </div>
      <div class="game-image game-left">
        <h2></h2>
        <img src="../assets/lost_or_found_screenshot.jpg" alt="Lost or Found Image"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Games',
  methods: {
    navigateTo(componentName) {
      this.$emit("navigate", componentName);
    },
  },
  props: {
    onlyFirst: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:root {
  --text-color: #ffffff;
  --primary-color: #77aabb;
  --secondary-color: #bb3344;
  --background-color: #333333;
}

a {
  display: inline-block;
  height: 36px;
  padding: 4px;
  color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}

a img {
  vertical-align: middle;
}

a:hover {
  background-color: var(--secondary-color);
}

#games {
  width: 50%;
  margin: auto;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.game {
  margin: 0 0 3em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  overflow: hidden;
}

.game-image img {
  max-width: 100%;
  height: auto;
}

.game-left {
  float: left;
  width: 49%;
}

.game-right {
  float: right;
  width: 49%;
}

.meta-info {
  text-align: center;
  font-size: 14px;
}

.links a {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  background-color: var(--primary-color);
  border-radius: 5px;
}

.links a img {
  vertical-align: middle;
}

.links a:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 1600px) {
  #games {
    width: 65%;
  }
}

@media (max-width: 1400px) {
  #games {
    width: 70%;
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  #games {
    width: 75%;
    font-size: 23px;
  }
}

@media (max-width: 1000px) {
  #games {
    width: 80%;
    font-size: 22px;
  }
}

@media (max-width: 800px) {
  #games {
    width: 85%;
    font-size: 21px;
  }
}

@media (max-width: 600px), (min-resolution: 2dppx) {
  #games {
    width: 90%;
    font-size: 20px;
  }

  .game-left {
    float: left;
    width: 100%;
  }

  .game-right {
    float: left;
    width: 100%;
  }
}
</style>