<template>
  <nav id="navbar" v-if="!['SP'].includes($route.name)">
    <ul>
      <li><router-link to="/"><img src="./assets/type3_logo_white_278x125.png" alt="Type 3 Studios Logo"></router-link></li>
      <li><router-link to="/games"><h1>GAMES</h1></router-link></li>
      <li><router-link to="/contact"><h1>CONTACT</h1></router-link></li>
    </ul>
  </nav>
  <main v-if="!['SP'].includes($route.name)">
    <div class="content">
      <router-view/>
    </div>

    <section id="footer">
      <div class="footer-container">
        <div class="footer-info">
          <div class="footer-logo">
            <h2></h2>
            <img src="./assets/type3_logo_white_278x125.png" alt="Type 3 Studios Logo">
          </div>
          <div class="footer-links">
            <h2>WITNESS US</h2>
            <a href="https://store.steampowered.com/search/?developer=Type%203%20Studios" target="_blank"><img src="./assets/steam36x36.png" alt="Steam"></a>
            <a href="https://www.youtube.com/channel/UCQ5mezHGELzMifxo8GU0qfg" target="_blank"><img src="./assets/youtube44x30.png" alt="Steam"></a>
            <a href="https://discord.gg/D6BuHNhXHJ" target="_blank"><img src="./assets/discord44x34.png" alt="Discord"></a>
            <a class="ml-onclick-form" href="javascript:void(0)" onclick="ml('show', 'Ux67wH', true)"><img src="./assets/mail36x36.png" alt="Open Mailing List Form"></a>
          </div>
          <div class="footer-sitemap">
            <h2>SITEMAP</h2>
            <ul>
              <li><router-link to="/">Welcome</router-link></li>
              <li><router-link to="/games">Games</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div>
        </div>
        <p class="copyright">© 2025 Type 3 Studios, LLC.</p>
      </div>
    </section>
  </main>
  
  <main style="margin-top: 0;" v-if="['SP'].includes($route.name)">
    <div class="content">
      <router-view/>
    </div>
  </main>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    // eslint-disable-next-line no-unexpected-multiline
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    // eslint-disable-next-line no-undef
    ml('account', '10129');
  }
}
</script>

<style>
#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

:root {
  --text-color: #ffffff;
  --primary-color: #77aabb;
  --secondary-color: #bb3344;
  --background-color: #333333;
  --background-alt-color: #666;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

#navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

#navbar ul {
  width: 50%;
  padding: 0px;
  background: linear-gradient(to right, var(--background-color), var(--primary-color), var(--background-color));
  margin: auto;
  display: flex;
}

#navbar li {
  margin: 0px 20px;
  flex: 1 1 200px;
  list-style-type: none;
  font-size: 25px;
  text-align: center;
}

#navbar a {
  display: block;
  padding: 20px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

#navbar a:hover {
  background-color: var(--secondary-color);
}

#navbar img {
  display:block;
  max-width: 178px;
  max-height: 80px;
  min-width: 134px;
  min-height: 60px;
  width: 100%;
  height: auto;
  margin: auto;
}

main {
  height: 80%;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

#footer {
  flex-shrink: 0;
  width: 100%;
  text-align: center;
}

.footer-container {
  width: 50%;
  height: 100%;
  margin: auto;
  border-top: 4px solid;
  border-top-color: var(--background-alt-color);
  border-image: linear-gradient(to right, var(--background-color), var(--primary-color), var(--background-color)) 1;
}

.footer-info {
  overflow: hidden;
}

.footer-info div {
  width: 33%;
  float: left;
}

.footer-logo img {
  margin: 10px;
}

.footer-container a {
  margin: 10px;
  height: 90px;
  vertical-align: middle;
  cursor: pointer;
}

.footer-links a {
  display: inline-block;
  height: 36px;
  padding: 4px;
  color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}

.footer-links a img {
  vertical-align: middle;
}

.footer-links a:hover {
  background-color: var(--secondary-color);
}

.footer-sitemap ul {
  text-align: center;
}

.footer-sitemap ul {
  list-style-type: none;
  padding: 0px;
}

.footer-sitemap ul li {
  margin: 10px;
  list-style-type: none;
  text-align: center;
}

.footer-sitemap a {
  padding: 4px;
  color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: 5px;
}

.footer-sitemap a:hover {
  background-color: var(--secondary-color);
}

.copyright {
  font-size: 14px;
  text-align: center;
}

@media (max-width: 1600px) {
  #navbar ul {
    width: 65%;
  }

  .footer-container {
    width: 65%;
  }
}

@media (max-width: 1400px) {
  #navbar ul {
    width: 70%;
  }

  #navbar li {
    margin: 0px 18px;
    flex: 1 1 180px;
    font-size: 24px;
  }

  #navbar a {
    padding: 18px;
    height: 78px;
  }

  #navbar img {
    max-width: 134px;
    max-height: 60px;
  }

  .footer-container {
    width: 70%;
  }

  .footer-info div {
    width: 33%;
  }
}

@media (max-width: 1200px) {
  #navbar ul {
    width: 75%;
  }

  #navbar li {
    margin: 0px 16px;
    flex: 1 1 160px;
    font-size: 23px;
  }

  #navbar a {
    padding: 16px;
    height: 76px;
  }

  .footer-container {
    width: 75%;
  }

  .footer-info div {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  #navbar ul {
    width: 80%;
  }

  #navbar li {
    margin: 0px 14px;
    flex: 1 1 140px;
    font-size: 22px;
  }

  #navbar a {
    padding: 14px;
    height: 74px;
  }

  .footer-container {
    width: 80%;
  }

  .footer-info div {
    width: 50%;
  }
}

@media (max-width: 800px) {
  #navbar ul {
    width: 85%;
  }

  #navbar li {
    margin: 0px 12px;
    flex: 1 1 100px;
    font-size: 21px;
  }

  #navbar a {
    padding: 12px;
    height: 72px;
  }

  .footer-container {
    width: 85%;
  }

  .footer-info div {
    width: 50%;
  }
}

@media (max-width: 600px) {
  #navbar ul {
    width: 90%;
  }

  #navbar li {
    margin: 0px 10px;
    flex: 1 1 50px;
    font-size: 14px;
  }

  #navbar a {
    padding: 10px;
    height: 70px;
  }

  .footer-container {
    width: 90%;
  }

  .footer-info div {
    width: 100%;
  }
}

@media (max-width: 400px), (min-resolution: 2dppx) {
  #navbar ul {
    width: 100%;
  }

  #navbar li {
    margin: 0px 10px;
    flex: 1 1 10px;
    font-size: 14px;
  }

  #navbar a {
    padding: 2px;
    height: 70px;
  }

  #navbar img {
    display:block;
    max-width: 134px;
    max-height: 60px;
    min-width: 35px;
    min-height: 15px;
    width: 100%;
    height: auto;
    margin: auto;
  }

  .footer-container {
    width: 90%;
  }

  .footer-info div {
    width: 100%;
  }
}
</style>
